exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-contentful-blog-post-slug-js": () => import("./../../../src/pages/blog/{contentfulBlogPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-slug-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-hire-web-3-developers-js": () => import("./../../../src/pages/hire-web3-developers.js" /* webpackChunkName: "component---src-pages-hire-web-3-developers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-slug-js": () => import("./../../../src/pages/{Page.slug}.js" /* webpackChunkName: "component---src-pages-page-slug-js" */),
  "component---src-pages-web-3-assessments-js": () => import("./../../../src/pages/web3-assessments.js" /* webpackChunkName: "component---src-pages-web-3-assessments-js" */),
  "component---src-pages-web-3-jobs-js": () => import("./../../../src/pages/web3-jobs.js" /* webpackChunkName: "component---src-pages-web-3-jobs-js" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-header-tsx": () => import("./../../../src/components/header.tsx" /* webpackChunkName: "slice---src-components-header-tsx" */)
}

